import * as d3 from 'd3';

export const calculateRegression = (data, xKey, yKey) => {
  const validData = data.filter(d => 
    !isNaN(+d[xKey]) && 
    !isNaN(+d[yKey]) && 
    d[xKey] !== null && 
    d[yKey] !== null
  );

  if (validData.length < 2) return { slope: 0, intercept: 0 };

  const xValues = validData.map(d => +d[xKey]);
  const yValues = validData.map(d => +d[yKey]);
  
  const xMean = d3.mean(xValues);
  const yMean = d3.mean(yValues);
  
  let numerator = 0;
  let denominator = 0;
  
  for(let i = 0; i < validData.length; i++) {
    numerator += (xValues[i] - xMean) * (yValues[i] - yMean);
    denominator += (xValues[i] - xMean) * (xValues[i] - xMean);
  }
  
  if (denominator === 0) return { slope: 0, intercept: yMean };
  
  const slope = numerator / denominator;
  const intercept = yMean - (slope * xMean);
  
  return { slope, intercept };
};

export const calculateCorrelation = (data, xKey, yKey) => {
  const validData = data.filter(d => 
    !isNaN(+d[xKey]) && 
    !isNaN(+d[yKey]) && 
    d[xKey] !== null && 
    d[yKey] !== null
  );

  if (validData.length < 2) return 0;

  const xValues = validData.map(d => +d[xKey]);
  const yValues = validData.map(d => +d[yKey]);
  
  const xMean = d3.mean(xValues);
  const yMean = d3.mean(yValues);
  
  let numerator = 0;
  let denominator1 = 0;
  let denominator2 = 0;
  
  for(let i = 0; i < validData.length; i++) {
    numerator += (xValues[i] - xMean) * (yValues[i] - yMean);
    denominator1 += Math.pow(xValues[i] - xMean, 2);
    denominator2 += Math.pow(yValues[i] - yMean, 2);
  }
  
  if (denominator1 === 0 || denominator2 === 0) return 0;
  
  return numerator / Math.sqrt(denominator1 * denominator2);
};

export const formatCurrency = value => {
  if (value >= 1000000) {
    return '$' + (value / 1000000).toFixed(1) + 'M';
  } else if (value >= 1000) {
    return '$' + (value / 1000).toFixed(1) + 'K';
  }
  return '$' + d3.format(',.0f')(value);
};
