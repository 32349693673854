import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Histogram from './histogram/Histogram1';
import HistogramControls from './histogram/histogramcontrols';
import Sidebar from './Sidebar';
import './histogram/Tooltip.css'; // Ensure correct path and casing

const HistogramPage = ({ session, theme, toggleTheme }) => {
  console.log('HistogramPage session:', session); // Debugging line
  const [searchParams] = useSearchParams();
  const [variable, setVariable] = useState(searchParams.get('variable') || 'AGE');
  const [sex, setSex] = useState(searchParams.get('sex') || 'both');
  const [status, setStatus] = useState(searchParams.get('status') || 'both');
  const [showControls, setShowControls] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showMobileDrawer, setShowMobileDrawer] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [loading, setLoading] = useState(true); // Added loading state

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Wrapper functions to update both state and URL
  const handleVariableChange = (newValue) => {
    setVariable(newValue);
  };

  const handleSexChange = (newValue) => {
    setSex(newValue);
  };

  const handleStatusChange = (newValue) => {
    setStatus(newValue);
  };

  return (
    <div style={{ backgroundColor: 'var(--bg-color)', minHeight: '100vh', display: 'flex' }}>
      {!isMobile && <Sidebar session={session} theme={theme} toggleTheme={toggleTheme} />}
      
      {/* Overlay for mobile drawer */}
      {isMobile && showMobileDrawer && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 999
          }}
          onClick={() => setShowMobileDrawer(false)}
        />
      )}

      <div style={{ 
        marginLeft: isMobile ? '0' : 'var(--sidebar-width, 0px)',
        width: isMobile ? '100%' : 'calc(100% - var(--sidebar-width, 0px))',
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh'
      }}>
        <div style={{
          padding: '10px',
          backgroundColor: 'var(--sub-alt-color)',
          borderBottom: '1px solid var(--sub-color)',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center', // Changed from 'space-between' to 'center'
          alignItems: 'center'
        }}>
          <h1 style={{ 
            color: 'var(--main-color)',
            margin: 0,
            transition: 'font-size 0.3s ease'
          }}>
            Histogram Distribution Analysis
          </h1>
          {isMobile && (
            <button
              onClick={() => setShowMobileDrawer(!showMobileDrawer)}
              style={{
                background: 'none',
                border: 'none',
                padding: '8px',
                cursor: 'pointer',
                position: 'absolute', // Added to position the button absolutely
                right: '10px',        // Positioned 10px from the right
                top: '20px'           // Increased from '10px' to '20px'
              }}
            >
              <span 
                className="material-symbols-outlined"
                style={{
                  color: 'var(--main-color)',
                  fontSize: '24px'
                }}
              >
                settings
              </span>
            </button>
          )}
        </div>

        <div style={{ 
          display: 'flex',
          flex: 1,
          position: 'relative',
          height: 'calc(100vh - 60px)',
          overflow: 'hidden'
        }}>
          <div style={{ 
            flex: 1,
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative' // Added to position tooltip correctly
          }}>
            {loading && (
              <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10
              }}>
                <div className="spinner"></div>
              </div>
            )}
            <Histogram
              variable={variable}
              sex={sex}
              status={status}
              setTooltipContent={setTooltipContent}
              setTooltipPosition={setTooltipPosition}
              setLoading={setLoading} // Pass setLoading to Histogram
            />
            {tooltipContent && (
              <div
                className="tooltip"
                style={{
                  position: 'absolute',
                  left: tooltipPosition.x,
                  top: tooltipPosition.y,
                  backgroundColor: 'var(--bg-color)',
                  color: 'var(--text-color)',
                  padding: '15px', // Increased padding
                  border: '1px solid var(--sub-color)',
                  borderRadius: '8px', // Increased border radius
                  pointerEvents: 'none',
                  fontFamily: "'Roboto Mono', monospace",
                  fontSize: '1.1em', // Increased font size
                  zIndex: 1000,
                  whiteSpace: 'pre-line',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)' // Added box shadow
                }}
              >
                {tooltipContent}
              </div>
            )}
          </div>

          {/* Mobile drawer */}
          {isMobile && (
            <div style={{
              position: 'fixed',
              top: 0,
              right: 0,
              width: '80%',
              maxWidth: '300px',
              height: '100vh',
              backgroundColor: 'var(--bg-color)',
              transform: `translateX(${showMobileDrawer ? '0' : '100%'})`,
              transition: 'transform 0.3s ease',
              zIndex: 1000,
              boxShadow: '-2px 0 5px rgba(0,0,0,0.2)',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <div style={{
                padding: '20px',
                borderBottom: '1px solid var(--sub-color)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'var(--sub-alt-color)'
              }}>
                <h3 style={{ margin: 0, color: 'var(--main-color)' }}>Controls</h3>
                <button
                  onClick={() => setShowMobileDrawer(false)}
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: '8px',
                    cursor: 'pointer'
                  }}
                >
                  <span 
                    className="material-symbols-outlined"
                    style={{ color: 'var(--main-color)' }}
                  >
                    close
                  </span>
                </button>
              </div>
              <div style={{ padding: '20px', overflow: 'auto' }}>
                <HistogramControls
                  variable={variable}
                  setVariable={handleVariableChange}
                  sex={sex}
                  setSex={handleSexChange}
                  status={status}
                  setStatus={handleStatusChange}
                />
              </div>
            </div>
          )}

          {/* Desktop controls */}
          {!isMobile && (
            <div style={{
              width: showControls ? '300px' : '0',
              backgroundColor: 'var(--sub-alt-color)',
              borderLeft: '1px solid var(--sub-color)',
              transition: 'width 0.3s ease',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative'
            }}>
              <button
                onClick={() => setShowControls(!showControls)}
                style={{
                  position: 'fixed',
                  padding: '8px',
                  backgroundColor: 'var(--sub-alt-color)',
                  border: '1px solid var(--sub-color)',
                  borderRadius: '8px 0 0 8px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1001,
                  top: '65px',
                  right: showControls ? '300px' : '0',
                  transition: 'right 0.3s ease'
                }}
              >
                <span 
                  className="settings-icon material-symbols-outlined"
                  style={{
                    color: 'var(--main-color)',
                    fontSize: '24px',
                    transform: showControls ? 'rotate(45deg)' : 'none',
                    transition: 'transform 0.3s ease'
                  }}
                >
                  settings
                </span>
              </button>

              <div style={{ padding: '20px', overflow: 'auto' }}>
                <HistogramControls
                  variable={variable}
                  setVariable={handleVariableChange}
                  sex={sex}
                  setSex={handleSexChange}
                  status={status}
                  setStatus={handleStatusChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Move export statement outside the component
export default HistogramPage;

