import React from 'react';
import Sidebar from './Sidebar';

const OnedrivePage = ({ session, theme, toggleTheme }) => {
  return (
    <div style={{ backgroundColor: 'var(--bg-color)', minHeight: '100vh', display: 'flex' }}>
      <Sidebar session={session} theme={theme} toggleTheme={toggleTheme} />
      <div style={{ 
        marginLeft: 'var(--sidebar-width, 0px)',
        width: 'calc(100% - var(--sidebar-width, 0px))',
        padding: '0 20px',
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '40px'
      }}>
        <h1 style={{ 
          color: 'var(--main-color)', 
          marginBottom: '30px',
          fontSize: '2.5em',
          textAlign: 'center'
        }}>
          Onedrive Folder
        </h1>
        <div style={{ width: '100%', maxWidth: '1200px' }}>
          {/* Embed the OneDrive folder */}
          <iframe
            title="Embedded OneDrive Folder View"
            src="https://cityuni-my.sharepoint.com/personal/liam_christian_city_ac_uk/_layouts/15/Doc.aspx?sourcedoc={9c8647f6-405a-43b6-b420-0ccf8c39c719}&amp;action=embedview"
            width="100%"
            height="600"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default OnedrivePage;
