import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import LoginPage from './components/LoginPage';
import DashboardPage from './components/DashboardPage';
import GraphsPage from './components/GraphsPage';
import ScatterPlotPage from './components/ScatterPlotPage';
import NotFound from './components/NotFound';
import HistogramPage from './components/HistogramPage';
import OnedrivePage from './components/OnedrivePage';
import { supabase } from './supabase';

function App() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || 'light');

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App" style={{ backgroundColor: 'var(--bg-color)', minHeight: '100vh' }}>
        <Routes>
          <Route 
            path="/" 
            element={
              session ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            } 
          />
          <Route
            path="/login"
            element={
              session ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <LoginPage theme={theme} toggleTheme={toggleTheme} />
              )
            }
          />
          <Route
            path="/dashboard"
            element={
              session ? (
                <DashboardPage session={session} theme={theme} toggleTheme={toggleTheme} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/scatter-plot"
            element={
              session ? (
                <ScatterPlotPage session={session} theme={theme} toggleTheme={toggleTheme} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/histogram"
            element={
              session ? (
                <HistogramPage session={session} theme={theme} toggleTheme={toggleTheme} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/graphs"
            element={
              session ? (
                <GraphsPage session={session} theme={theme} toggleTheme={toggleTheme} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route 
            path="/onedrive" 
            element={<OnedrivePage session={session} theme={theme} toggleTheme={toggleTheme} />} 
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
