import React from 'react';

const HistogramControls = ({ variable, setVariable, sex, setSex, status, setStatus }) => {
  const variables = [
    { value: 'AGE', label: 'Age (Years)' },
    { value: 'LOS', label: 'Length of Stay (Days)' },
    { value: 'TC', label: 'Total Charges ($)' }
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {/* Variable selection */}
      <div>
        <h4 style={{ color: 'var(--main-color)', marginBottom: '10px' }}>Variable</h4>
        <select 
          value={variable}
          onChange={(e) => setVariable(e.target.value)}
          style={{
            width: '100%',
            padding: '8px',
            backgroundColor: 'var(--bg-color)',
            color: 'var(--text-color)',
            border: '1px solid var(--sub-color)',
            borderRadius: '4px'
          }}
        >
          {variables.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>

      {/* Sex selection */}
      <div>
        <h4 style={{ color: 'var(--main-color)', marginBottom: '10px' }}>Sex</h4>
        <select 
          value={sex}
          onChange={(e) => setSex(e.target.value)}
          style={{
            width: '100%',
            padding: '8px',
            backgroundColor: 'var(--bg-color)',
            color: 'var(--text-color)',
            border: '1px solid var(--sub-color)',
            borderRadius: '4px'
          }}
        >
          <option value="both">Both</option>
          <option value="M">Male</option>
          <option value="F">Female</option>
        </select>
      </div>

      {/* Status selection */}
      <div>
        <h4 style={{ color: 'var(--main-color)', marginBottom: '10px' }}>Status</h4>
        <select 
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          style={{
            width: '100%',
            padding: '8px',
            backgroundColor: 'var(--bg-color)',
            color: 'var(--text-color)',
            border: '1px solid var(--sub-color)',
            borderRadius: '4px'
          }}
        >
          <option value="both">Both</option>
          <option value="alive">Alive</option>
          <option value="deceased">Deceased</option>
        </select>
      </div>
    </div>
  );
};

export default HistogramControls;
