import * as d3 from 'd3';

const createHistogram = (container, data, variable, setTooltipContent, setTooltipPosition) => {
  // Increase top margin and adjust spacings
  const margin = { top: 120, right: 30, bottom: 50, left: 60 };
  
  // Get container dimensions
  const containerWidth = container.node().clientWidth;
  const containerHeight = container.node().clientHeight;

  // Calculate responsive dimensions
  const width = containerWidth - margin.left - margin.right;
  const height = Math.min(
    containerHeight - margin.top - margin.bottom,
    // Ensure height is reasonable on mobile
    Math.max(width * 0.6, 300)
  );

  // Clear existing SVG
  container.selectAll('*').remove();

  const svg = container.append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Stats formatting helper
  const formatStat = (value, variable) => {
    switch(variable) {
      case 'TC':
        return `$${Number(value).toLocaleString()}`;
      case 'AGE':
        return `${Number(value).toFixed(1)} years`;
      case 'LOS':
        return `${Number(value).toFixed(1)} days`;
      default:
        return value;
    }
  };

  // Create stats box
  const stats = {
    total: data.length-1,
    mean: formatStat(d3.mean(data, d => +d[variable]), variable),
    median: formatStat(d3.median(data, d => +d[variable]), variable)
  };

  // Stats display with adjusted positioning
  const statsGroup = svg.append('g')
    .attr('transform', `translate(${width/2}, ${-margin.top/2 + 20})`);

  const statsText = [
    { label: 'Total', value: stats.total.toLocaleString() },
    { label: 'Mean', value: stats.mean },
    { label: 'Median', value: stats.median }
  ];

  statsText.forEach((stat, i) => {
    const statGroup = statsGroup.append('g')
      .attr('transform', `translate(${(i - 1) * 200}, 0)`);

    // Label
    statGroup.append('text')
      .attr('x', 0)
      .attr('y', -15)
      .style('text-anchor', 'middle')
      .style('fill', 'var(--sub-color)')
      .style('font-family', "'Roboto Mono', monospace")
      .style('font-size', '0.9em')
      .text(stat.label);

    // Value
    statGroup.append('text')
      .attr('x', 0)
      .attr('y', 5)
      .style('text-anchor', 'middle')
      .style('fill', 'var(--text-color)')
      .style('font-family', "'Roboto Mono', monospace")
      .style('font-size', '1.1em')
      .style('font-weight', 'bold')
      .text(stat.value);
  });

  // Format numbers based on variable type
  const formatValue = (value, variable) => {
    switch(variable) {
      case 'TC':
        return `$${Number(value).toLocaleString()}`;
      case 'AGE':
        return `${Math.round(value)} years`;
      case 'LOS':
        return `${Math.round(value)} days`;
      default:
        return value;
    }
  };

  // Determine number of bins based on data type
  const getBinCount = (variable) => {
    switch(variable) {
      case 'AGE':
        return 20;  // reasonable for age ranges
      case 'LOS':
        return 15;  // length of stay tends to be shorter
      case 'TC':
        return 25;  // more bins for financial data
      default:
        return 20;
    }
  };

  // X axis
  const x = d3.scaleLinear()
    .domain(variable === 'TC' 
      ? [0, d3.max(data, d => +d[variable]) * 1.05]  // Add 5% padding for TC
      : d3.extent(data, d => +d[variable]))
    .nice()
    .range([0, width]);

  const xAxis = svg.append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x));
  
  xAxis.selectAll('text')
    .style('font-size', '12px')
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace");

  xAxis.selectAll('line')
    .style('stroke', 'var(--axis-color)');

  xAxis.selectAll('path')
    .style('stroke', 'var(--axis-color)');

  // Histogram bins
  const histogram = d3.histogram()
    .value(d => +d[variable])
    .domain(x.domain())
    .thresholds(x.ticks(getBinCount(variable)));

  const bins = histogram(data);

  // Y axis
  const y = d3.scaleLinear()
    .domain([0, d3.max(bins, d => d.length)])
    .nice()
    .range([height, 0]);

  // Update Y axis position and styling
  const yAxis = svg.append('g')
    .call(d3.axisLeft(y));

  yAxis.selectAll("text")
    .style('font-size', '12px')
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace");

  yAxis.selectAll('line')
    .style('stroke', 'var(--axis-color)');

  yAxis.selectAll('path')
    .style('stroke', 'var(--axis-color)');

  // Bars
  svg.selectAll('rect')
    .data(bins)
    .enter()
    .append('rect')
      .attr('x', 1)
      .attr('transform', d => `translate(${x(d.x0)},${y(d.length)})`)
      .attr('width', d => Math.max(0, x(d.x1) - x(d.x0) - 1))
      .attr('height', d => height - y(d.length))
      .style('fill', 'var(--main-color)')
      .on('mouseover', (event, d) => {
        const formattedStart = formatValue(d.x0, variable);
        const formattedEnd = formatValue(d.x1, variable);
        setTooltipContent([
          `Range: ${formattedStart} - ${formattedEnd}`,
          `Count: ${d.length}`
        ].join('\n'));
        setTooltipPosition({ x: event.pageX, y: event.pageY });
      })
      .on('mouseout', () => {
        setTooltipContent(null);
      });

  // X axis label
  const getAxisLabel = (variable) => {
    switch(variable) {
      case 'TC':
        return 'Total Charges (USD)';
      case 'AGE':
        return 'Age (Years)';
      case 'LOS':
        return 'Length of Stay (Days)';
      default:
        return variable;
    }
  };

  svg.append('text')
    .attr('x', width / 2 )
    .attr('y', height + margin.bottom - 5 )
    .style('text-anchor', 'middle')
    .text(getAxisLabel(variable))
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace");

  // Update Y axis label position
  svg.append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', -height / 2)
    .attr('y', -margin.left + 12)
    .style('text-anchor', 'middle')
    .style('dominant-baseline', 'middle')
    .text('Count')
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace");

  // Update title position and styling with lower position
  svg.append('text')
    .attr('x', width / 2)
    .attr('y', -margin.top + 40)  // Adjusted from 25 to 40
    .style('text-anchor', 'middle')
    .style('dominant-baseline', 'middle')
    .style('fill', 'var(--main-color)')
    .style('font-family', "'Roboto Mono', monospace")
    .style('font-size', '1.4em')
    .style('font-weight', 'bold')
    .text(`Distribution of ${getAxisLabel(variable)}`);
};

export default createHistogram;
