import React from 'react';
import Auth from './Auth';

const LoginPage = ({ theme, toggleTheme }) => {
  return (
    <div style={{ backgroundColor: 'var(--bg-color)', minHeight: '100vh' }}>
      <Auth theme={theme} toggleTheme={toggleTheme} />
    </div>
  );
};

export default LoginPage;
