export const plotConfigs = {
  'age-tc': { x: 'AGE', y: 'TC', label: 'Age vs Total Cost' },
  'age-los': { x: 'AGE', y: 'LOS', label: 'Age vs Length of Stay' },
  'los-tc': { x: 'LOS', y: 'TC', label: 'Length of Stay vs Total Cost' }
};

export const getResponsiveConfig = (containerWidth) => {
  return {
    margin: {
      top: Math.max(30, Math.min(50, containerWidth * 0.04)),
      right: Math.max(25, Math.min(50, containerWidth * 0.04)),
      bottom: Math.max(40, Math.min(60, containerWidth * 0.05)),
      left: Math.max(60, Math.min(100, containerWidth * 0.09))
    },
    pointRadius: Math.max(2, Math.min(5, containerWidth * 0.004)),
    fontSize: {
      axis: Math.max(10, Math.min(12, containerWidth * 0.01)),
      title: Math.max(12, Math.min(16, containerWidth * 0.015)),
      legend: Math.max(8, Math.min(12, containerWidth * 0.01))
    }
  };
};

export const getFilterDescription = (sex, status) => {
  const sexDesc = sex === 'both' ? '' : (sex === 'M' ? ' Male' : ' Female');
  const statusDesc = status === 'both' ? '' : (status === 'deceased' ? ' Deceased' : ' Living');
  return `${sexDesc}${statusDesc}`.trim();
};

export const filterData = (data, sex, status) => {
  return data.filter(d => {
    const sexMatch = sex === 'both' ? true : d.SEX === sex;
    
    const statusMatch = status === 'both' ? true :
      (status === 'alive' ? d.DIED === '0' : d.DIED === '1');
    
    return sexMatch && statusMatch;
  });
};
