import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabase';
import ThemeToggle from './ThemeToggle';

const Sidebar = ({ theme, toggleTheme, session }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Initialize isCollapsed from localStorage or default to false
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const storedState = localStorage.getItem('isCollapsed');
    return storedState ? JSON.parse(storedState) : false;
  });
  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  // Initialize openMenus from localStorage or default to empty object
  const [openMenus, setOpenMenus] = useState(() => {
    const storedMenus = localStorage.getItem('openMenus');
    return storedMenus ? JSON.parse(storedMenus) : {};
  });

  // Define menuItems before any hooks that use it
  const menuItems = [
    { path: '/dashboard', label: 'Dashboard', icon: 'dashboard' },
    {
      label: 'Graphs',
      path: '/graphs', // Added path for navigation
      icon: 'analytics',
      subItems: [
        { path: '/scatter-plot', label: 'Scatter Plot' },
        { path: '/histogram', label: 'Histogram' }
      ]
    }
  ];

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Persist isCollapsed state to localStorage
  useEffect(() => {
    localStorage.setItem('isCollapsed', JSON.stringify(isCollapsed));
    document.documentElement.style.setProperty('--sidebar-width', isCollapsed ? '0px' : '200px');
  }, [isCollapsed]);

  // Persist openMenus state to localStorage
  useEffect(() => {
    localStorage.setItem('openMenus', JSON.stringify(openMenus));
  }, [openMenus]);

  const isActive = (path) => location.pathname === path;

  const toggleMenu = (label) => {
    setOpenMenus((prev) => ({ ...prev, [label]: !prev[label] }));
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  const getUsername = () => {
    if (session?.user?.name) {
      return session.user.name;
    } else if (session?.user?.email) {
      return session.user.email.split('@')[0];
    }
    return 'User';
  };

  return (
    <div style={{
      width: isCollapsed ? '0' : '200px',
      height: '100vh',
      backgroundColor: 'var(--sub-alt-color)',
      borderRight: '1px solid var(--sub-color)',
      position: 'fixed',
      left: 0,
      top: 0,
      padding: '20px 0',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      transition: 'width 0.3s ease',
      overflow: 'hidden',
      zIndex: 1000
    }}>
      <div style={{
        padding: '0 20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px'
      }}>
        <img 
          src="/favicon.svg" 
          alt="Logo"
          onClick={() => navigate('/dashboard')}
          style={{
            height: '150px',
            cursor: 'pointer',
            transition: 'transform 0.2s ease',
            objectFit: 'contain'
          }}
        />
        <span style={{ 
          color: 'var(--text-color)',
          fontSize: '1.1em',
          textAlign: 'center'
        }}>
          Hi, {getUsername()}!
        </span>
      </div>
      {menuItems.map(item => (
        <div key={item.label || item.path}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* Clickable label to navigate */}
            <button
              onClick={() => {
                if (item.path) navigate(item.path); // Navigate if path exists
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                padding: '12px 20px',
                backgroundColor: isActive(item.path) ? 'var(--main-color)' : 'transparent',
                color: isActive(item.path) ? 'white' : 'var(--text-color)',
                border: 'none',
                cursor: 'pointer',
                flex: 1,
                textAlign: 'left',
                transition: 'all 0.2s ease'
              }}
            >
              <span
                className="material-symbols-outlined"
                style={{ color: 'var(--text-color)' }}
              >
                {item.icon}
              </span>
              {item.label}
            </button>
            
            {/* Caret icon to toggle dropdown */}
            {item.subItems && (
              <button
                onClick={() => toggleMenu(item.label)}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '12px 20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <span className="material-symbols-outlined" style={{ color: 'var(--text-color)' }}>
                  {openMenus[item.label] ? 'expand_less' : 'expand_more'}
                </span>
              </button>
            )}
          </div>
          {item.subItems && openMenus[item.label] && (
            <div style={{ paddingLeft: '20px' }}>
              {item.subItems.map(sub => (
                <button
                  key={sub.path}
                  onClick={() => navigate(sub.path)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    padding: '8px 20px',
                    backgroundColor: isActive(sub.path) ? 'var(--main-color)' : 'transparent',
                    color: isActive(sub.path) ? 'white' : 'var(--text-color)',
                    border: 'none',
                    cursor: 'pointer',
                    width: '100%',
                    textAlign: 'left',
                    transition: 'all 0.2s ease'
                  }}
                >
                  <span className="material-symbols-outlined" style={{ color: 'var(--text-color)' }}>
                    subdirectory_arrow_right
                  </span>
                  {sub.label}
                </button>
              ))}
            </div>
          )}
        </div>
      ))}

      <div style={{
        marginTop: 'auto',
        padding: '20px',
        borderTop: '1px solid var(--sub-color)',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        alignItems: 'center'
      }}>
        <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
        <button
          onClick={handleLogout}
          style={{
            padding: '8px 16px',
            backgroundColor: 'transparent',
            color: 'var(--main-color)',
            border: '1px solid var(--main-color)',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '0.9em',
            width: '100%',
            transition: 'all 0.2s ease'
          }}
        >
          Sign Out
        </button>
      </div>

      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        style={{
          position: 'fixed',
          padding: '8px',
          backgroundColor: 'var(--sub-alt-color)',
          border: '1px solid var(--sub-color)',
          borderRadius: isMobile ? '0 0 8px 8px' : '0 8px 8px 0',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1001,
          ...(isMobile ? {
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
          } : {
            top: '60px', // Changed from 20px to 60px
            left: isCollapsed ? '0' : '200px',
            transition: 'left 0.3s ease',
          })
        }}
      >
        <span 
          className="material-symbols-outlined"
          style={{
            transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: 'transform 0.3s ease',
            color: 'var(--text-color)'
          }}
        >
          chevron_left
        </span>
      </button>
    </div>
  );
};

export default Sidebar;