import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';


const GraphsPage = ({ session, theme, toggleTheme }) => {
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: 'var(--bg-color)', minHeight: '100vh', display: 'flex' }}>
      <Sidebar session={session} theme={theme} toggleTheme={toggleTheme} />
      <div style={{ 
        marginLeft: 'var(--sidebar-width, 0px)',
        width: 'calc(100% - var(--sidebar-width, 0px))',
        padding: '0 20px',
        transition: 'all 0.3s ease'
      }}>
        <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          zIndex: 10
        }}>
        </div>
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: '20px', 
          alignItems: 'center', 
          width: '100%',
          paddingTop: '80px'
        }}>          
          <h1 style={{ 
            color: 'var(--main-color)', 
            marginBottom: '30px',
            fontSize: '2.5em',
            textAlign: 'center'
          }}>
            Interactive Graphs & Visualisations
          </h1>

          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 300px), 1fr))',
            gap: '20px',
            width: '90%',
            maxWidth: '1200px',
            margin: '20px auto'
          }}>
            {/* Scatter Plot Box - Clickable */}
            <div 
              onClick={() => navigate('/scatter-plot')}
              style={{
                backgroundColor: 'var(--sub-alt-color)',
                border: `2px solid var(--main-color)`,
                borderRadius: '10px',
                padding: '20px',
                cursor: 'pointer',
                transition: 'transform 0.3s ease',
                textAlign: 'center',
                minHeight: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
              }}
            >
              <h3 style={{ 
                color: 'var(--text-color)',
                marginBottom: '15px',
                fontSize: '1.3em'
              }}>
                Bivariate Scatter Plots with Line of Regression
              </h3>
              <p style={{ color: 'var(--text-color)' }}>
                Click to explore relationships between variables with regression analysis
              </p>
            </div>
            {/* Histogram Box - Clickable */}
            <div 
              onClick={() => navigate('/histogram')}
              style={{
                backgroundColor: 'var(--sub-alt-color)',
                border: `2px solid var(--main-color)`,
                borderRadius: '10px',
                padding: '20px',
                cursor: 'pointer',
                transition: 'transform 0.3s ease',
                textAlign: 'center',
                minHeight: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
              }}
            >
              <h3 style={{ 
                color: 'var(--text-color)',
                marginBottom: '15px',
                fontSize: '1.3em'
              }}>
                Histogram Visualizations
              </h3>
              <p style={{ color: 'var(--text-color)' }}>
                Click to explore data distributions with histograms
              </p>
            </div>

            {/* Coming Soon Boxes - Disabled */}
            {[1, 2].map((index) => (
              <div 
                key={index}
                style={{
                  backgroundColor: 'var(--sub-alt-color)',
                  border: `2px solid var(--sub-color)`,
                  borderRadius: '10px',
                  padding: '20px',
                  opacity: 0.5,
                  cursor: 'not-allowed',
                  textAlign: 'center',
                  minHeight: '200px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <h3 style={{ color: 'var(--sub-color)' }}>Coming Soon</h3>
                <p style={{ color: 'var(--sub-color)' }}>More visualizations will be available in future updates</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphsPage;
