import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { plotConfigs, getResponsiveConfig, filterData } from '../../config/plotConfig';
import createScatterPlot from './visualization/ScatterPlotVis';
import create2DDensity from './visualization/DensityPlotVis';
import Papa from 'papaparse';

const ScatterPlot = ({ visType, plotType, sex, status, jitterEnabled, setLoading }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const loadData = async () => {
      try {
        setLoading(true);
        const response = await fetch('/all_data.csv');
        const csvText = await response.text();
        const { data } = Papa.parse(csvText, { header: true });
        
        const filteredData = filterData(data, sex, status);
        const config = plotConfigs[plotType];
        const responsiveConfig = getResponsiveConfig(container.clientWidth);
        
        // Clear previous visualization
        d3.select(container).selectAll('*').remove();
        const d3Container = d3.select(container);

        // Create visualization based on type
        visType === 'scatter' 
          ? createScatterPlot(d3Container, filteredData, config, responsiveConfig, sex, status, jitterEnabled)
          : create2DDensity(d3Container, filteredData, config, responsiveConfig, sex, status);

        setLoading(false);
      } catch (error) {
        console.error('Error loading data:', error);
        setLoading(false);
      }
    };

    loadData();

    const handleResize = () => {
      loadData();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [visType, plotType, sex, status, jitterEnabled, setLoading]);

  return (
    <div 
      ref={containerRef} 
      className="visualization-container"
    />
  );
};

export default ScatterPlot;
