import React, { useState } from 'react';
import { supabase } from '../supabase';
import ThemeToggle from './ThemeToggle';

const Auth = ({ theme, toggleTheme }) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithPassword({
        email: `${username}@citymaths.co.uk`,
        password,
      });
      if (error) throw error;
    } catch (error) {
      setError('Invalid username or password');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container" style={{ 
      maxWidth: '400px', 
      margin: '0 auto', 
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px'
    }}>
      <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
        <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
      </div>

      <img 
        src="/favicon.svg" 
        alt="Logo" 
        style={{ 
          width: '300px',
          marginBottom: '20px',
          marginTop: '40px'
        }} 
      />

      <form onSubmit={handleLogin} style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: '15px',
        width: '100%'
      }}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{
            padding: '10px',
            borderRadius: '4px',
            border: '1px solid var(--sub-color)',
            fontSize: '16px',
            backgroundColor: 'var(--bg-color)',
            color: 'var(--text-color)',
            width: '100%',
            boxSizing: 'border-box'
          }}
        />
        <div style={{ position: 'relative', width: '100%' }}>
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{
              padding: '10px',
              borderRadius: '4px',
              border: '1px solid var(--sub-color)',
              fontSize: '16px',
              width: '100%',
              backgroundColor: 'var(--bg-color)',
              color: 'var(--text-color)',
              boxSizing: 'border-box'
            }}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              color: 'var(--sub-color)',
              padding: '0'
            }}
          >
            {showPassword ? '👁️' : '👁️‍🗨️'}
          </button>
        </div>
        {error && <div style={{ color: 'var(--error-color)', marginBottom: '10px' }}>{error}</div>}
        <button
          type="submit"
          disabled={loading}
          style={{
            padding: '10px',
            backgroundColor: 'var(--main-color)',
            color: 'var(--bg-color)',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'opacity 0.2s',
            width: '100%'
          }}
        >
          {loading ? 'Loading...' : 'Sign In'}
        </button>
      </form>
    </div>
  );
};

export default Auth;
