import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabase';
import ScatterPlot from './scatter/ScatterPlot';
import Controls from './scatter/Controls';
import Sidebar from './Sidebar';

const ScatterPlotPage = ({ session, theme, toggleTheme }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [visType, setVisType] = useState(searchParams.get('visType') || 'scatter');
  const [plotType, setPlotType] = useState(searchParams.get('plotType') || 'age-los');
  const [sex, setSex] = useState(searchParams.get('sex') || 'both');
  const [status, setStatus] = useState(searchParams.get('status') || 'both');
  const [jitterEnabled, setJitterEnabled] = useState(searchParams.get('jitter') !== 'false');
  const [showControls, setShowControls] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showMobileDrawer, setShowMobileDrawer] = useState(false);
  const [titleFontSize, setTitleFontSize] = useState('1.8em');
  const [loading, setLoading] = useState(true);

  // Update URL parameters when states change
  useEffect(() => {
    const params = new URLSearchParams();
    params.set('visType', visType);
    params.set('plotType', plotType);
    params.set('sex', sex);
    params.set('status', status);
    params.set('jitter', jitterEnabled);
    setSearchParams(params);
  }, [visType, plotType, sex, status, jitterEnabled, setSearchParams]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const calculateFontSize = () => {
      const width = window.innerWidth;
      if (width <= 480) {
        setTitleFontSize('1.2em');
      } else if (width <= 768) {
        setTitleFontSize('1.5em');
      } else {
        setTitleFontSize('1.8em');
      }
    };

    calculateFontSize();
    window.addEventListener('resize', calculateFontSize);
    return () => window.removeEventListener('resize', calculateFontSize);
  }, []);

  const handleVisTypeChange = (newValue) => {
    setVisType(newValue);
  };

  const handlePlotTypeChange = (newValue) => {
    setPlotType(newValue);
  };

  const handleSexChange = (newValue) => {
    setSex(newValue);
  };

  const handleStatusChange = (newValue) => {
    setStatus(newValue);
  };

  const getUsername = () => {
    if (session?.user?.email) {
      return session.user.email.split('@')[0];
    }
    return '';
  };

  const renderMobileDrawer = () => (
    <div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'var(--sub-alt-color)',
      borderTop: '1px solid var(--sub-color)',
      transform: `translateY(${showMobileDrawer ? '0' : '100%'})`,
      transition: 'transform 0.3s ease',
      zIndex: 1000,
      maxHeight: '80vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <div style={{
        padding: '15px',
        borderBottom: '1px solid var(--sub-color)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <span style={{ color: 'var(--main-color)', fontWeight: 'bold' }}>
          Hi, {getUsername()}!
        </span>
        <button
          onClick={() => setShowMobileDrawer(false)}
          style={{
            background: 'none',
            border: 'none',
            color: 'var(--main-color)',
            cursor: 'pointer'
          }}
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>
      
      <div style={{ overflow: 'auto', padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ borderBottom: '1px solid var(--sub-color)', paddingBottom: '20px' }}>
          <div style={{ fontSize: '1.1em', color: 'var(--main-color)', marginBottom: '15px', fontWeight: 'bold' }}>
            Navigation
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {[
              { path: '/dashboard', label: 'Dashboard', icon: 'dashboard' },
              { path: '/graphs', label: 'Graphs', icon: 'analytics' }
            ].map(item => (
              <button
                key={item.path}
                onClick={() => navigate(item.path)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  padding: '12px',
                  backgroundColor: 'transparent',
                  color: 'var(--text-color)',
                  border: '1px solid var(--sub-color)',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'left'
                }}
              >
                <span className="material-symbols-outlined">{item.icon}</span>
                {item.label}
              </button>
            ))}
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <div style={{ fontSize: '1.1em', color: 'var(--main-color)', marginBottom: '15px', fontWeight: 'bold' }}>
            Graph Settings
          </div>
          <Controls
            visType={visType}
            setVisType={handleVisTypeChange}
            plotType={plotType}
            setPlotType={handlePlotTypeChange}
            sex={sex}
            setSex={handleSexChange}
            status={status}
            setStatus={handleStatusChange}
            jitterEnabled={jitterEnabled}
            setJitterEnabled={setJitterEnabled}
          />
        </div>

        <div style={{ 
          borderTop: '1px solid var(--sub-color)', 
          paddingTop: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        }}>
          <button
            onClick={toggleTheme}
            style={{
              padding: '12px',
              backgroundColor: 'transparent',
              color: 'var(--main-color)',
              border: '1px solid var(--main-color)',
              borderRadius: '4px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px'
            }}
          >
            <span className="material-symbols-outlined">
              {theme === 'dark' ? 'light_mode' : 'dark_mode'}
            </span>
            {theme === 'dark' ? 'Light Mode' : 'Dark Mode'}
          </button>
          <button
            onClick={() => {
              supabase.auth.signOut();
              navigate('/login');
            }}
            style={{
              padding: '12px',
              backgroundColor: 'transparent',
              color: 'var(--error-color)',
              border: '1px solid var(--error-color)',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Sign Out
          </button>
        </div>
      </div>
    </div>
  );

  const renderMobileToggleButton = () => (
    <button
      onClick={() => setShowMobileDrawer(true)}
      style={{
        position: 'fixed',
        bottom: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '12px 24px',
        backgroundColor: 'var(--main-color)',
        color: 'var(--bg-color)',
        border: 'none',
        borderRadius: '25px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        zIndex: 999,
        cursor: 'pointer'
      }}
    >
      <span className="material-symbols-outlined">settings</span>
      Settings
    </button>
  );

  return (
    <div style={{ backgroundColor: 'var(--bg-color)', minHeight: '100vh', display: 'flex' }}>
      {!isMobile && <Sidebar session={session} theme={theme} toggleTheme={toggleTheme} />}
      <div style={{ 
        marginLeft: isMobile ? '0' : 'var(--sidebar-width, 0px)',
        width: isMobile ? '100%' : 'calc(100% - var(--sidebar-width, 0px))',
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          backgroundColor: 'var(--sub-alt-color)',
          borderBottom: '1px solid var(--sub-color)',
          position: 'relative'
        }}>
          <h1 style={{ 
            color: 'var(--main-color)',
            margin: 0,
            fontSize: titleFontSize,
            transition: 'font-size 0.3s ease'
          }}>
            Bivariate Scatter Plots with Line of Regression
          </h1>
        </div>

        <div style={{ 
          display: 'flex',
          flex: 1,
          position: 'relative',
          height: 'calc(100vh - 60px)',
          overflow: 'hidden'
        }}>
          <div style={{ 
            flex: 1,
            padding: '20px 40px 40px 40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'padding 0.3s ease',
            ...(showControls ? {
              paddingRight: '20px'
            } : {
              paddingRight: '60px'
            })
          }}>
            {loading && (
              <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10
              }}>
                <div className="spinner"></div>
              </div>
            )}
            <ScatterPlot
              visType={visType}
              plotType={plotType}
              sex={sex}
              status={status}
              jitterEnabled={jitterEnabled}
              setLoading={setLoading}
            />
          </div>

          {!isMobile && (
            <div style={{
              width: showControls ? '300px' : '0',
              backgroundColor: 'var(--sub-alt-color)',
              borderLeft: '1px solid var(--sub-color)',
              transition: 'width 0.3s ease',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative'
            }}>
              <button
                onClick={() => setShowControls(!showControls)}
                style={{
                  position: 'fixed',
                  padding: '8px',
                  backgroundColor: 'var(--sub-alt-color)',
                  border: '1px solid var(--sub-color)',
                  borderRadius: '8px 0 0 8px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1001,
                  top: '60px',
                  right: showControls ? '300px' : '0',
                  transition: 'right 0.3s ease'
                }}
              >
                <span 
                  className="settings-icon material-symbols-outlined"
                  style={{
                    color: 'var(--main-color)',
                    fontSize: '24px',
                    transform: showControls ? 'rotate(45deg)' : 'none',
                    transition: 'transform 0.3s ease'
                  }}
                >
                  settings
                </span>
              </button>

              <div style={{ padding: '20px', overflow: 'auto' }}>
                <Controls
                  visType={visType}
                  setVisType={handleVisTypeChange}
                  plotType={plotType}
                  setPlotType={handlePlotTypeChange}
                  sex={sex}
                  setSex={handleSexChange}
                  status={status}
                  setStatus={handleStatusChange}
                  jitterEnabled={jitterEnabled}
                  setJitterEnabled={setJitterEnabled}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {isMobile && (
        <>
          {renderMobileToggleButton()}
          {renderMobileDrawer()}
        </>
      )}
    </div>
  );
};

export default ScatterPlotPage;
