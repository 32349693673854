import * as d3 from 'd3';
import { formatCurrency } from '../../../utils/calculations';
import { getFilterDescription } from '../../../config/plotConfig';
import createLegend from '../../../components/visualization/Legend';
import { calculateRegression, calculateCorrelation } from '../../../utils/calculations';

const create2DDensity = (container, data, config, responsiveConfig, sex, status) => {
  const { margin, fontSize } = responsiveConfig;
  const validData = data.filter(d => 
    !isNaN(+d[config.x]) && 
    !isNaN(+d[config.y]) && 
    d[config.x] !== null && 
    d[config.y] !== null
  );

  if (validData.length === 0) return;

  const width = container.node().clientWidth - margin.left - margin.right;
  const height = Math.min(
    container.node().clientHeight - margin.top - margin.bottom,
    width * 0.75
  );

  const svg = container.append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const x = d3.scaleLinear()
    .domain(d3.extent(validData, d => +d[config.x]))
    .nice()
    .range([0, width]);

  const y = d3.scaleLinear()
    .domain(d3.extent(validData, d => +d[config.y]))
    .nice()
    .range([height, 0]);

  const densityData = validData.map(d => ([+d[config.x], +d[config.y]]));
  
  // Adjust bandwidth based on data size
  const bandwidth = Math.max(15, Math.min(25, width * 0.02));
  
  // Scale the density calculation
  const densityScale = Math.max(100, validData.length / 20);
  
  const contours = d3.contourDensity()
    .x(d => x(d[0]))
    .y(d => y(d[1]))
    .size([width, height])
    .bandwidth(bandwidth)
    .thresholds(30)
    (densityData);

  // Scale up the density values and round to integer
  const maxDensity = Math.round(d3.max(contours, d => d.value * densityScale));
  
  // Create a more sophisticated color scale with multiple color stops
  const color = d3.scaleSequential()
    .domain([0, maxDensity])
    .interpolator(t => {
      // Define a custom color interpolation with more gradual transitions
      const colors = [
        '#dec9e9', '#dac3e8', '#d2b7e5', '#c19ee0', '#b185db', '#a06cd5', '#9163cb', '#815ac0', '#7251b5', '#6247aa'
      ];
      
      // Calculate which segment of the gradient we're in
      const i = Math.min(Math.floor(t * (colors.length - 1)), colors.length - 2);
      const f = t * (colors.length - 1) - i;
      
      // Use d3's built-in color interpolation for smooth transitions
      return d3.interpolate(colors[i], colors[i + 1])(f);
    });

  svg.insert("g", "g")
    .selectAll("path")
    .data(contours)
    .enter().append("path")
    .attr("d", d3.geoPath())
    .attr("fill", d => color(Math.round(d.value * densityScale)))
    .attr("stroke", "none")
    .style("opacity", 0.85); // Slightly reduce opacity for better blending

  // Calculate and add regression line
  const regression = calculateRegression(validData, config.x, config.y);
  const correlation = calculateCorrelation(validData, config.x, config.y);

  const regressionLine = d3.line()
    .x(d => x(d))
    .y(d => y(regression.slope * d + regression.intercept));

  svg.append("path")
    .datum(d3.extent(validData, d => +d[config.x]))
    .attr("class", "regression-line")
    .attr("d", regressionLine)
    .style("stroke", "red")
    .style("stroke-width", Math.max(2, Math.min(4, width * 0.004)))
    .style("fill", "none");

  // Add axes with themed colors and font
  const xAxis = svg.append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x));

  xAxis.selectAll('text')
    .style('font-size', `${fontSize.axis}px`)
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace");

  xAxis.selectAll('line')
    .style('stroke', 'var(--axis-color)');

  xAxis.selectAll('path')
    .style('stroke', 'var(--axis-color)');

  const yAxis = svg.append('g')
    .call(d3.axisLeft(y)
      .tickFormat(config.y === 'TC' ? formatCurrency : d3.format('d')));

  yAxis.selectAll('text')
    .style('font-size', `${fontSize.axis}px`)
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace");

  yAxis.selectAll('line')
    .style('stroke', 'var(--axis-color)');

  yAxis.selectAll('path')
    .style('stroke', 'var(--axis-color)');

  // Add axis labels with themed colors and font
  svg.append('text')
    .attr('x', width / 2)
    .attr('y', height + margin.bottom * 0.7)
    .style('text-anchor', 'middle')
    .style('font-size', `${fontSize.axis}px`)
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace")
    .text(config.x === 'AGE' ? 'Age (Years)' : 'Length of Stay (Days)');

  svg.append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', -height / 2)
    .attr('y', -margin.left * 0.85)
    .style('text-anchor', 'middle')
    .style('font-size', `${fontSize.axis}px`)
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace")
    .text(config.y === 'TC' ? 'Total Cost ($)' : (config.y === 'LOS' ? 'Length of Stay (Days)' : config.y));

  // Add title with larger font and underline
  const filterDesc = getFilterDescription(sex, status);
  const titleText = filterDesc ? `${config.label} for ${filterDesc} Patients` : config.label;
  
  svg.append('text')
    .attr('x', width / 2)
    .attr('y', -margin.top / 2)
    .style('text-anchor', 'middle')
    .style('font-size', `${fontSize.title}px`)
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace")
    .text(titleText);

  // Create legend
  createLegend(svg, width, color, true, correlation, responsiveConfig, validData.length, maxDensity);

  return { svg, width, height, x, y, colorScale: color, validData };
};

export default create2DDensity;
