import * as d3 from 'd3';

const createLegend = (svg, width, colorScale, showRegression = true, correlation = null, config, dataCount, maxDensity) => {
  const legendWidth = Math.min(150, width * 0.25);
  const legendHeight = Math.max(10, Math.min(15, width * 0.015));
  const legendTop = config.margin.top / 2;
  const legendSpacing = Math.max(25, Math.min(35, width * 0.04));
  
  const legend = svg.append('g')
    .attr('class', 'legend')
    .attr('transform', `translate(${config.margin.left}, ${legendTop})`);

  const defs = svg.append('defs');
  const linearGradient = defs.append('linearGradient')
    .attr('id', `linear-gradient-${Math.random()}`);

  linearGradient.selectAll('stop')
    .data(colorScale.ticks().map((t, i, n) => ({ 
      offset: `${100 * i / n.length}%`,
      color: colorScale(t) 
    })))
    .enter().append('stop')
    .attr('offset', d => d.offset)
    .attr('stop-color', d => d.color);

  // Point Density Legend
  const densityLegend = legend.append('g');
  
  densityLegend.append('rect')
    .attr('width', legendWidth)
    .attr('height', legendHeight)
    .style('fill', `url(#${linearGradient.attr('id')})`);

  // Create density scale using the actual maxDensity
  const legendAxis = d3.axisBottom(d3.scaleLinear()
    .domain([0, maxDensity])
    .range([0, legendWidth]))
    .ticks(4)
    .tickFormat(d3.format('d')); // Changed to 'd' format for integers

  densityLegend.append('g')
    .attr('transform', `translate(0,${legendHeight})`)
    .call(legendAxis)
    .selectAll('text')
    .style('font-size', `${config.fontSize.legend}px`)
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace");

  densityLegend.append('text')
    .attr('x', legendWidth / 2)
    .attr('y', -5)
    .style('text-anchor', 'middle')
    .style('font-size', `${config.fontSize.legend}px`)
    .style('fill', 'var(--text-color)')
    .style('font-family', "'Roboto Mono', monospace")
    .text('Points per Area');

  if (showRegression) {
    // Regression Line Legend
    const regressionLegend = legend.append('g')
      .attr('transform', `translate(0,${legendHeight + legendSpacing})`);

    regressionLegend.append('line')
      .attr('x1', 0)
      .attr('x2', 25)
      .attr('y1', 0)
      .attr('y2', 0)
      .style('stroke', 'red')
      .style('stroke-width', Math.max(2, Math.min(4, width * 0.004)));

    regressionLegend.append('text')
      .attr('x', 30)
      .attr('y', 4)
      .style('font-size', `${config.fontSize.legend}px`)
      .style('fill', 'var(--text-color)')
      .style('font-family', "'Roboto Mono', monospace")
      .text(`Regression Line (r = ${correlation?.toFixed(3) || '0.000'})`);

    // Data Points Count Legend
    const countLegend = legend.append('g')
      .attr('transform', `translate(0,${legendHeight + legendSpacing * 2})`);

    countLegend.append('text')
      .attr('x', 0)
      .attr('y', 4)
      .style('font-size', `${config.fontSize.legend}px`)
      .style('fill', 'var(--text-color)')
      .style('font-family', "'Roboto Mono', monospace")
      .text(`Total Data Points: ${d3.format(",")(dataCount)}`);
  }

  return { legendHeight: legendHeight + legendSpacing * 3 };
};

export default createLegend;
