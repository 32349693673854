import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import createHistogram from './visualization/HistogramVis';

const Histogram = ({ variable, sex, status, setTooltipContent, setTooltipPosition, setLoading }) => {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const loadData = async () => {
      try {
        setLoading(true); // Start loading
        const response = await fetch('/all_data.csv');
        const csvText = await response.text();
        const data = d3.csvParse(csvText);

        // Filter data based on sex and status
        const filteredData = data.filter(d => {
          const sexMatch = sex === 'both' ? true : d.SEX === sex;
          const statusMatch = status === 'both' ? true : 
            (status === 'alive' ? d.DIED === '0' : d.DIED === '1');
          return sexMatch && statusMatch;
        });

        // Remove any existing SVG
        d3.select(container).selectAll('*').remove();
        const d3Container = d3.select(container);

        // Create histogram visualization with tooltip handlers
        createHistogram(d3Container, filteredData, variable, setTooltipContent, setTooltipPosition);

        setLoading(false); // End loading
      } catch (error) {
        console.error('Error loading data:', error);
        setLoading(false); // End loading on error
      }
    };

    loadData();

    const handleResize = () => {
      loadData();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

  }, [variable, sex, status, setTooltipContent, setTooltipPosition, setLoading]);

  return (
    <div 
      ref={containerRef} 
      className="visualization-container"
      style={{
        width: '100%',
        height: '100%',
        minHeight: '400px',
        position: 'relative'
      }}
    />
  );
};

export default Histogram;
