import React, { useEffect, useRef, useState} from 'react';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { AsciiEffect } from 'three/examples/jsm/effects/AsciiEffect';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const asciiArt = `
                                                  40000   .400004.      40000                                                                 
                                                404 000  4004  4004    404 000                                                                 
                                                404 000  000     000   404 000                                                                 
                                              404   000  000     000  404  000                                                                 
                                              400   000  000     000 400   000                                                                 
                                              0000000000 000     000 0000000000                                                                
                                                    000  4004  4004       000                                                                 
                                                    000   "400004"        000                                                                 
                                                                                                                                      
                                    
00000004.                                   0004    000          000          0000000000                              000 000 
000   4004                                  00004   000          000          000                                     000 000 
000    000                                  000004  000          000          000                                     000 000 
000   4004  00004.   .4004.   .4004.        0004004 000  .4004.  000000       0000000  .4004.  000  000 000004.   .400000 000 
00000004"      "004 4004"004 404  404       000 4004000 400""004 000          000     400""004 000  000 000 "004 400" 000 000 
000        .4000000 000  000 00000000       000  400000 000  000 000          000     000  000 000  000 000  000 000  000 404 
000        000  000 4004 000 404.           000   40000 400..004 4004.        000     400..004 4004 000 000  000 4004 000  "  
000        "4000000  "400000  "40000        000    4000  "4004"   "4000       000      "4004"   "400000 000  000  "400000 000 
                         000                                                                                                  
                    404 4004                                                                                                  
                     "4004"                                                                                                   

`

  const [fontSize, setFontSize] = useState(12);

  useEffect(() => {
    if (!containerRef.current) return;

    // Scene setup
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0, 0, 0);

    // Sizes
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    // Camera setup with fixed distance to maintain consistent view
    const distance = 25;
    const camera = new THREE.PerspectiveCamera(50, sizes.width / sizes.height, 0.1, 2000);
    camera.position.set(0, 0, distance);
    camera.lookAt(0, 0, 0);

    // Calculate visible bounds at z=0
    const vFOV = camera.fov * Math.PI / 180;
    const visibleHeight = 2 * Math.tan(vFOV / 2) * distance;
    const visibleWidth = visibleHeight * camera.aspect;

    // Store bounds as percentage of visible area to maintain consistency
    const boundsPercent = 0.85; // Leave some margin for the model size
    const bounds = {
      x: (visibleWidth / 2) * boundsPercent,
      y: (visibleHeight / 2) * boundsPercent
    };

    // Renderer setup
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(sizes.width, sizes.height);

    // ASCII effect setup
    const effect = new AsciiEffect(renderer,' |/\\`{}[]~:;@^%.four04OFOUR', { 
      invert: true,
      resolution: 0.205,
      scale: 0.9
    });
    effect.setSize(sizes.width, sizes.height);
    effect.domElement.style.color = 'white';
    effect.domElement.style.backgroundColor = 'black';
    effect.domElement.style.position = 'absolute';
    effect.domElement.style.top = '0';
    effect.domElement.style.left = '0';

    // Store the current container element
    const currentContainer = containerRef.current;
    currentContainer.appendChild(effect.domElement);

    // Lighting setup
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    const pointLight1 = new THREE.PointLight(0xffffff, 1, 0, 0);
    pointLight1.position.set(100, 100, 400);
    scene.add(pointLight1);

    const pointLight2 = new THREE.PointLight(0xffffff, 1);
    pointLight2.position.set(-500, 100, -400);
    scene.add(pointLight2);

    // Material
    const material = new THREE.MeshStandardMaterial({
      flatShading: true,
      side: THREE.DoubleSide,
      metalness: 0.3,
      roughness: 0.7
    });

    // Create mesh container
    const myMesh = new THREE.Mesh();
    myMesh.material = material;

    // Create clock for animation
    const clock = new THREE.Clock();

    // Movement state
    const velocity = {
      x: 2,
      y: 1.5
    };

    // Create wrapper div for ASCII art that acts as a link
    const asciiWrapper = document.createElement('div');
    asciiWrapper.style.position = 'absolute';
    asciiWrapper.style.top = '0';
    asciiWrapper.style.left = '0';
    asciiWrapper.style.width = '100%';
    asciiWrapper.style.textAlign = 'center';
    asciiWrapper.style.cursor = 'pointer';
    asciiWrapper.style.zIndex = '1000';
    asciiWrapper.onclick = () => navigate('/');

    const asciiArtDiv = document.createElement('pre');
    asciiArtDiv.style.color = 'white';
    asciiArtDiv.style.fontFamily = 'monospace';
    asciiArtDiv.style.lineHeight = '0.8em';
    asciiArtDiv.style.margin = '0';
    asciiArtDiv.style.fontSize = `${fontSize}px`;
    asciiArtDiv.textContent = asciiArt;

    asciiWrapper.appendChild(asciiArtDiv);
    currentContainer.appendChild(asciiWrapper);

    // Add copyright text
    const copyrightDiv = document.createElement('a');
    copyrightDiv.href = 'https://liamchristian.com';
    copyrightDiv.target = '_blank';
    copyrightDiv.style.position = 'absolute';
    copyrightDiv.style.bottom = '10px';
    copyrightDiv.style.left = '50%';
    copyrightDiv.style.transform = 'translateX(-50%)';
    copyrightDiv.style.color = 'white';
    copyrightDiv.style.fontSize = '8px';
    copyrightDiv.style.textDecoration = 'none';
    copyrightDiv.style.fontFamily = 'monospace';
    copyrightDiv.style.opacity = '0.7';
    copyrightDiv.style.zIndex = '1000';
    copyrightDiv.textContent = '© Liam Christian 2024';
    currentContainer.appendChild(copyrightDiv);

    // Function to dynamically adjust font size
    const adjustFontSize = () => {
      const screenWidth = window.innerWidth;
      const textWidth = asciiArtDiv.scrollWidth;
      
      if (textWidth > screenWidth) {
        const ratio = screenWidth / textWidth;
        const newFontSize = Math.floor(fontSize * ratio * 0.95); // 0.95 to add a small margin
        setFontSize(newFontSize);
        asciiArtDiv.style.fontSize = `${newFontSize}px`;
      }
    };

    // Load STL file
    const stlLoader = new STLLoader();
    stlLoader.load(
      '/404withfish.stl',
      (geometry) => {
        myMesh.geometry = geometry;
        
        geometry.computeVertexNormals();
        myMesh.geometry.center();

        myMesh.rotation.x = -90 * Math.PI / 180;
        myMesh.scale.set(3.0, 3.0, 3.0);

        scene.add(myMesh);

        // Animation
        function tick() {
          const delta = clock.getDelta();

          // Update position with slower speed
          myMesh.position.x += velocity.x * delta;
          myMesh.position.y += velocity.y * delta;

          // Rotate slowly
          myMesh.rotation.z += 0.5 * delta;

          // Bounce off window edges with improved physics
          if (Math.abs(myMesh.position.x) > bounds.x) {
            velocity.x = -velocity.x;
            myMesh.position.x = Math.sign(myMesh.position.x) * bounds.x;
          }
          if (Math.abs(myMesh.position.y) > bounds.y) {
            velocity.y = -velocity.y;
            myMesh.position.y = Math.sign(myMesh.position.y) * bounds.y;
          }

          effect.render(scene, camera);
          requestAnimationFrame(tick);
        }

        tick();
      }
    );

    // Handle window resize
    const handleResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      // Update bounds on resize while maintaining aspect ratio
      const vFOV = camera.fov * Math.PI / 180;
      const visibleHeight = 2 * Math.tan(vFOV / 2) * distance;
      const visibleWidth = visibleHeight * (sizes.width / sizes.height);
      
      bounds.x = (visibleWidth / 2) * boundsPercent;
      bounds.y = (visibleHeight / 2) * boundsPercent;

      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      renderer.setSize(sizes.width, sizes.height);
      effect.setSize(sizes.width, sizes.height);
      
      // Adjust font size on resize
      adjustFontSize();
    };

    window.addEventListener('resize', handleResize);

    // Initial font size adjustment
    adjustFontSize();

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      currentContainer.removeChild(asciiWrapper);
      currentContainer.removeChild(copyrightDiv);
      if (currentContainer) {
        currentContainer.removeChild(effect.domElement);
      }
      renderer.dispose();
    };
  }, [asciiArt, fontSize, navigate]);

  return (
    <div ref={containerRef} style={{ width: '100vw', height: '100vh', position: 'relative', overflow: 'hidden', backgroundColor: 'black' }}>
    </div>
  );
};

export default NotFound;
