import React from 'react';

const Controls = ({ 
  visType, 
  setVisType, 
  plotType,
  setPlotType,
  sex,
  setSex,
  status,
  setStatus,
  jitterEnabled,
  setJitterEnabled
}) => {
  // Removed unused state and variables

  const selectStyle = {
    width: '100%',
    padding: '8px',
    backgroundColor: 'var(--bg-color)',
    color: 'var(--text-color)',
    border: '1px solid var(--sub-color)',
    borderRadius: '4px',
    marginTop: '5px'
  };

  const switchStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px',
    backgroundColor: 'var(--bg-color)',
    border: '1px solid var(--sub-color)',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '5px',
    width: '100%'
  };

  const resetButtonStyle = {
    padding: '10px',
    backgroundColor: 'var(--bg-color)',
    color: 'var(--text-color)',
    border: '1px solid var(--sub-color)',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '20px'
  };

  const iconStyle = {
    color: 'var(--text-color)',
    fontSize: '18px',
    cursor: 'pointer',
    marginLeft: '5px'
  };

  const handleReset = () => {
    setVisType('scatter');
    setSex('both');
    setStatus('both');
    setJitterEnabled(true);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {/* Plot Type */}
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4 style={{ color: 'var(--main-color)', marginBottom: '5px' }}>Plot Type</h4>
          <span 
            className="material-symbols-outlined"
            style={iconStyle}
            title="Select the variables to plot on the X and Y axes."
          >
            help_outline
          </span>
        </div>
        <select
          value={plotType}
          onChange={(e) => setPlotType(e.target.value)}
          style={selectStyle}
        >
          <option value="age-los">Age vs Length of Stay</option>
          <option value="age-tc">Age vs Total Charge</option>
          <option value="los-tc">Length of Stay vs Total Charge</option>
        </select>
      </div>

      {/* Visualization Type */}
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4 style={{ color: 'var(--main-color)', marginBottom: '5px' }}>Visualization Type</h4>
          <span 
            className="material-symbols-outlined"
            style={iconStyle}
            title="Choose between Scatter Plot and Density Plot."
          >
            help_outline
          </span>
        </div>
        <select
          value={visType}
          onChange={(e) => setVisType(e.target.value)}
          style={selectStyle}
        >
          <option value="scatter">Scatter Plot</option>
          <option value="density">Density Plot</option>
        </select>
      </div>

      {/* Sex Filter */}
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4 style={{ color: 'var(--main-color)', marginBottom: '5px' }}>Sex</h4>
          <span 
            className="material-symbols-outlined"
            style={iconStyle}
            title="Filter data by patient sex."
          >
            help_outline
          </span>
        </div>
        <select
          value={sex}
          onChange={(e) => setSex(e.target.value)}
          style={selectStyle}
        >
          <option value="both">Both</option>
          <option value="M">Male</option>
          <option value="F">Female</option>
        </select>
      </div>

      {/* Status Filter */}
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4 style={{ color: 'var(--main-color)', marginBottom: '5px' }}>Status</h4>
          <span 
            className="material-symbols-outlined"
            style={iconStyle}
            title="Filter data by patient status."
          >
            help_outline
          </span>
        </div>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          style={selectStyle}
        >
          <option value="both">Both</option>
          <option value="alive">Alive</option>
          <option value="deceased">Deceased</option>
        </select>
      </div>

      {/* Jitter Toggle (only show for scatter plot) */}
      {visType === 'scatter' && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ color: 'var(--main-color)', marginBottom: '5px', textAlign: 'center' }}>Point Jittering</h4>
              <span 
                className="material-symbols-outlined"
                style={iconStyle}
                title="Enable or disable point jittering for better visibility of overlapping points."
              >
                help_outline
              </span>
            </div>
            <button
              onClick={() => setJitterEnabled(!jitterEnabled)}
              style={switchStyle}
            >
              <span 
                className="material-symbols-outlined"
                style={{ 
                  color: jitterEnabled ? 'var(--main-color)' : 'var(--sub-color)', 
                  fontSize: '40px' 
                }}
              >
                {jitterEnabled ? 'toggle_on' : 'toggle_off'}
              </span>
            </button>
          </div>
        </div>
      )}

      {/* Reset Button */}
      <button
        onClick={handleReset}
        style={resetButtonStyle}
      >
        <span 
          className="material-symbols-outlined"
          style={{ color: 'var(--text-color)' }}
        >
          restart_alt
        </span>
        Reset Settings
      </button>
    </div>
  );
};

export default Controls;
